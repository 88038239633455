/* Динамические экраны. Замена заголовков и фонов на главном блоке в зависимости от значений в URL */
import {getUrlVars} from '../modules/utm';
var content = window.location.pathname.split('/')[1];
var geo = getUrlVars()['utm_geo'];
var headerImg = document.querySelector('.section-1');
var headerText = document.querySelector('.section-1__title');



// 1
if (content === 'perevoznyye-bani') {
    var titles = {
        'all': 'Перевозные бани из профилированного бруса под ключ от производителя',
        'krasnodar': 'Перевозные бани из профилированного бруса с доставкой в Краснодар под ключ от производителя'
    }
    headerText.innerHTML = titles[geo] ? titles[geo] : titles['all'];
    headerImg.classList.add('perevoznyye_bani');
    headerImg.classList.add('d_content');
}
// 2
if (content === 'mobilnyye-bani') {
    var titles = {
        'all': 'Мобильные бани из профилированного бруса под ключ от производителя',
        'krasnodar': 'Мобильные бани из профилированного бруса с доставкой в Краснодар под ключ от производителя'
    }
    headerText.innerHTML = titles[geo] ? titles[geo] : titles['all'];
    headerImg.classList.add("mobilnyye_bani");
    headerImg.classList.add('d_content');
}
// // 3
if (content === 'gotovyye-bani') {
    var titles = {
        'all': 'Готовые бани из профилированного бруса под ключ от производителя',
        'krasnodar': 'Готовые бани из профилированного бруса с доставкой в Краснодар под ключ от производителя'
    }
    headerText.innerHTML = titles[geo] ? titles[geo] : titles['all'];

    headerImg.classList.add("gotovyye_bani");
    headerImg.classList.add('d_content');
}
// //4
if (content === 'banya-na-dachu') {
    var titles = {
        'all': 'Баня на дачу из профилированного бруса под ключ от производителя',
        'krasnodar': 'Баня на дачу в Краснодарском крае из профилированного бруса под ключ по выгодной цене '
    }
    headerText.innerHTML = titles[geo] ? titles[geo] : titles['all'];
    headerImg.classList.add('banya_na_dachu');
    headerImg.classList.add('d_content');
}
// // 5
if (content === 'banya-iz-brusa') {
    var titles = {
        'all': 'Баня из бруса под ключ от производителя',
        'krasnodar': 'Строительство бань из профилированного бруса в Краснодарском крае под ключ по выгодной цене'
    }
    headerText.innerHTML = titles[geo] ? titles[geo] : titles['all'];
    headerImg.classList.add('banya_iz_brusa');
    headerImg.classList.add('d_content');
}
// // 6
if (content === 'banya-v-kredit-rassrochku') {
    var titles = {
        'all': 'Баня в рассрочку или в кредит на выгодных условиях',
        'krasnodar': 'Баня в рассрочку или в кредит в Краснодарском крае на выгодных условиях'
    }
    headerText.innerHTML = titles[geo] ? titles[geo] : titles['all'];
    headerImg.classList.add('banya_v_kredit_rassrochku');
    headerImg.classList.add('d_content');
}
// // 7
if (content === 'bani-iz-pestovo') {
    var titles = {
        'all': 'Бани из Пестово под ключ от производителя с доставкой по всей России',
        'krasnodar': 'Бани из Пестово под ключ от производителя с доставкой по всей России'
    }
    headerText.innerHTML = titles[geo] ? titles[geo] : titles['all'];
    headerImg.classList.add('bani_iz_pestovo');
    headerImg.classList.add('d_content');
}
// // 8
if (content === 'kaliningrad') {
    var titles = {
        'all': 'Бани из Бруса и Каркаса<br> от Производителя с Доставкой<br> в Калининград',
        'krasnodar': 'Бани из Пестово под ключ от производителя с доставкой по всей России'
    }
    headerText.innerHTML = titles[geo] ? titles[geo] : titles['all'];
    //headerImg.classList.add('kaliningrad'');
    headerImg.classList.add('d_content');
}
