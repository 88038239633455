import $ from 'jquery';
import '../vendor/maskedinput.min';
import '../vendor/jquery.validationEngine';
import '../vendor/jquery.validationEngine-ru';
import '../vendor/jquery.flexslider';
import {addHideParam} from '../modules/utm';
// Отдаёт значения get параметров в соответствии с переданным массивом имён
$.extend({
  getUrlVars: () => {
    const vars = [];
    let hash;

    const { href } = window.location;
    const hashes = window.location.href.slice(href.indexOf("?") + 1).split("&");
    for (let i = 0; i < hashes.length; i += 1) {
      hash = hashes[i].split("=");
      vars.push(hash[0]);
      const [key, value] = hash;
      vars[key] = value;
    }
    return vars;
  },
  getUrlVar: (name) => $.getUrlVars()[name],
});

const $modalOverlay = $(".modal-overlay");
const $modalWindow = $(".modal-window");
const $openModal = $(".open-modal");
const $modalClose = $(".modal-close");
const $body = $("body");
const $html = $("html");
const $stock = $("#stock");

$modalOverlay.hide();
$modalWindow.hide();
$modalWindow.click((e) => {
  e.stopPropagation();
});

function closeModal() {
  //console.log('closeModal');

  // console.log('e.target.parent: ', e.target.parentElement);

  $(".modal-window").css({
    display: "none",
  });
  $modalWindow.removeClass("modal--opened");
  $modalOverlay
    .css({
      display: "none",
    })
    .children()
    .appendTo($body);

  // $modalOverlay.hide();
  // $modalWindow.hide();

  $html.css({
    overflow: "auto",
    width: "auto",
  });

  // setTimeout(() => {
  //   $('.modal-window').css({ display: 'none' });
  // }, 100);
}

$modalClose.on("click", closeModal);

function openModal($el) {
  $el.appendTo($modalOverlay).css({
    display: "block",
  });
  $html.css({
    overflow: "hidden",
    width: $html.outerWidth(),
  });
  $modalOverlay.css({
    "overflow-y": "scroll",
    display: "block",
  });
  $el.addClass("modal--opened");
}

const psLinks = () => {
  const ttCode = "0401013003483";
  const ttName = "г. Санкт-Петербург, Ул. Заозерная д. 8 к. 2";
  const returnUrl = "https://xn--80abcls3adbfvl.xn--p1ai/";
  // const price = 10;

  /*     $('#pb-baner').queue(function() {
            const $this = $(this);
            $this.attr(
                'href',
                'https://my.pochtabank.ru/pos-credit?' +
                $.param({
                    ttCode,
                    ttName,
                    returnUrl,
                    manualOrderInput: true
                })
            );
        }); */
  //

  $("#pb-baner").click(function () {
    openModal($("#credit"));
  });

  $(".loco-bank").click(function () {
    openModal($("#credit"));
  });

  $(".pb-credit-button").click(function () {
    openModal($("#credit"));
  });

  // const posCreditLink = document.querySelectorAll('pos-credit-link');

  // console.log(posCreditLink);

  // const addEventListenerToList = (list, event, fn) => {
  //   for (let i = 0, len = list.length; i < len; i += 1) {
  //     list[i].addEventListener(event, fn, false);
  //     console.log(list[i]);
  //   }
  // };

  const bathhouseOnCreditGoal = () => {
    ga("send", "event", "bathhouse_on_credit", "send");
    yaCounter42705444.reachGoal("bathhouse_on_credit");
    console.log("bathhouse_on_credit -> send");
  };

  // addEventListenerToList(posCreditLink, 'click', bathhouseOnCreditGoal);

  $(".pos-credit-link").click(bathhouseOnCreditGoal);

  $(".pos-credit-link").queue(function () {
    const $this = $(this);
    const price = parseFloat($this.attr("data-price").replace(/ /g, ""));
    $this.attr(
      "href",
      "https://my.pochtabank.ru/pos-credit?" +
        $.param({
          ttCode,
          ttName,
          returnUrl,
          // manualOrderInput: false,
          order: [
            {
              category: "249",
              price,
            },
          ],
        })
    );
  });

  // console.log($('.pb-credit-button'));
  // const $buttons = $('.pb-credit-button a');
  // $buttons.each((el) => {
  //
  //     const $href = $buttons[el].attr('href');
  //     console.log($href);
  // });

  // $('.pos-credit-link ').on('click', (e) => {
  //     e.preventDefault();
  //     console.log('open');
  //     const params = encodeURI($.param(options));
  //     const url = `https://my.pochtabank.ru/pos-credit?${params}`;
  //     window.open(url, '_blank');
  // });

  // $('.pos-credit-link').attr('href', 'https://my.pochtabank.ru/pos-credit?' + $.param(options));
};

const openStockModal = () => {
  const utmMedium = $.getUrlVar("utm_medium");

  //console.log('> utmMedium: ', utmMedium);

  if (utmMedium === "market") {
    console.log("> market link");
    return false;
  }

  // Отложенный показ модального окна
  var timeout;
  if (window.innerWidth > 767) {
    timeout = 30000; // Десктоп
    console.log(timeout);
  } else {
    timeout = 30000; // Моб и Таблет
    console.log(timeout);
  }

  setTimeout(function () {
    // Проверяет открыто ли какое-либо модальное окно
    if (!$modalWindow.hasClass("modal--opened")) openModal($("#stock"));
  }, timeout);

  return true;
};

//Загрузка документа
$(document).ready(function () {
  ga(function (tracker) {
    var clientId = tracker.get('clientId');
    jQuery('input[name="cid"]').val(clientId);
  });

  $(".current_year").text(new Date().getFullYear());
  setTimeout(function () {
    // openModal($('#stock'));
    // openModal($('#thanx'));
  }, 500);

  // ================================================================
  //  Переливание текста
  $(".anim-text-flow").html(function (i, html) {
    var chars = $.trim(html).split("");

    return "<span>" + chars.join("</span><span>") + "</span>";
  });
  // ================================================================

  if ($("#stock").length) {
    openStockModal();
  }

  // функция для получения cookie по имени
/*   function getCookie(name) {
    var matches = document.cookie.match(
      new RegExp(
        "(?:^|; )" +
          name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
          "=([^;]*)"
      )
    );
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  function insertCid() {
    var cidLong = '';
    // исходное значение cookie с именем _ga
    if (cidLong = getCookie("_ga")) {
          // делим на части используя .
    var tmp = cidLong.split(".");
    // берем 3 и 4 фрагмент и склеиваем
    var cid_ga = tmp[2] + "." + tmp[3];
    // возвращаем значение
    jQuery('input[name="cid"]').val(cid_ga);
    //console.log("cid= ", cid_ga);
    }
  } */

  psLinks();

  $("input[name='request[Телефон]']").mask("+7 ( 999 ) 999 - 99 - 99");
  $(".phone-event").click(function () {
    gtag("event", "sendform", {
      event_category: phone,
      event_action: "send",
    });
    console.log("phone->click");
  });

  /* Toggle between adding and removing the "responsive" class to topnav when the user clicks on the icon */

  $(".topnav a").on("click", function () {
    var x = document.getElementById("myTopnav");
    if (!$(this).attr("menu-open")) {
      if (x.className === "topnav") {
        x.className += " responsive";
      } else {
        x.className = "topnav";
      }
    }
  });

  //Для кроссбраузерных плейсхолдеров
  var $inputFields = $(
    '.placeholder input[type="text"], .placeholder textarea'
  );

  $inputFields.each(function () {
    if ($(this).val() != "")
      $(this).css({
        backgroundColor: "#fff",
      });
  });
  $inputFields.blur(function () {
    if ($(this).val() == "")
      $(this).css({
        backgroundColor: "transparent",
      });
  });
  $inputFields.focus(function () {
    $(this).css({
      backgroundColor: "#fff",
    });
  });
  $inputFields.change(function () {
    if ($(this).val() != "")
      $(this).css({
        backgroundColor: "#fff",
      });
  });
  $inputFields.mouseover(function () {
    if ($(this).val() != "")
      $(this).css({
        backgroundColor: "#fff",
      });
  });

  $modalWindow.on("click", ".modal-project__imgs-item", function (e) {
    e.preventDefault();
    e.stopPropagation();

    var $el = $(this);
    var src = $el.find("img").attr("src");
    var $img = $el.parent().prev().find("img");
    $img.attr("src", src);
  });

  $('.header__nav a:not(".menu-icon")').click(function (e) {
    e.preventDefault();

    $("html, body").animate(
      {
        scrollTop: $($(this).attr("href")).offset().top,
      },
      1000
    );
  });

  $openModal.click(function (e) {
    e.preventDefault();
    var $el = $(this);
    var href = $el.attr("href");
    var $win = $(href);

    if (href == "#custom") {
      // var obj = {};
      $('input[type="radio"]:checked, select').each(function () {
        var $el = $(this);
        // obj[$el.attr('name')] = $el.val();
        $win.find('[name="' + $el.attr("name") + '"]').val($el.val());
      });
    }

    $("input[name='request[Телефон]']").mask("+7 ( 999 ) 999 - 99 - 99");

    if (href === "#mobile-baths" || href === "#build-baths") {
      const $parent = $el.closest(".product__item-inner");
      const $price = $parent.find(".product__item-new-price");
      const $name = $parent.find(".product__item-name");
      const price = $price.text();

      const size = $name.text().slice(5);
      const id = $el.attr("data-id");
      const prefix = href === "#build-baths" ? "build" : "mobile";
      const imgCount = href === "#build-baths" ? 6 : 3;

      $win.find(".modal-project__size").text(size);
      $win.find(".modal-project__price").text(price);

      // $win
      //   .find('.modal-project__img img')
      //   .attr('src', 'img/' + prefix + 'baths/pr' + index + '-it0.jpg');

      // $win.find('.modal-project__imgs-item').each(function(i, item) {
      //   $(item)
      //     .find('img')
      //     .attr(
      //       'src',
      //       'img/' + prefix + 'baths/pr' + index + '-it' + i + '.jpg'
      //     );
      // });

      const imgId = id
        .replace(/,/g, "-")
        .replace(/Х/g, "X") // костыль
        .toLocaleLowerCase();

      let modalProjectSliderHTML =
        '<div class="flexslider"><ul class="slides">';

      for (let k = 0; k < imgCount; k++) {
        modalProjectSliderHTML += `<li data-thumb="img/${prefix}-baths/${imgId}-it${k}.jpg">
                <img src="img/${prefix}-baths/${imgId}-it${k}.jpg" data-src="img/${prefix}-baths/${imgId}-it${k}.jpg"/></li>`;
      }

      modalProjectSliderHTML += "</ul></div>";
      // console.log('modalProjectSliderHTML: ', modalProjectSliderHTML);

      $(".modal-project__slider").html(modalProjectSliderHTML);
      // .modal-project__slider
      $(".flexslider").flexslider({
        animation: "slide",
        animationLoop: false,
        smoothHeight: true,
        slideshow: true,
        //controlNav: 'thumbnails'
      });

      if ($el.attr("data-standart")) {
        $win.find(".standart").hide();
        $win.find(".standart").next().show();
      } else {
        $win.find(".standart").show();
        $win.find(".standart").next().hide();
      }

      if ($el.attr("data-terrasa")) {
        $win.find(".terrasa").show();
      } else {
        $win.find(".terrasa").hide();
      }
    }

    if ($el.attr("data-action")) {
      $win.find('form input[name="action"]').val($el.attr("data-action"));
    }
    if ($el.attr("data-subject")) {
      $win.find('form input[name="subject"]').val($el.attr("data-subject"));
    }

    openModal($win);
    //insertCid();
  });

  $(".section-7__button").click(function (e) {
    e.preventDefault();

    openModal($("#specification"));
  });

  $modalOverlay.click(function () {
    closeModal();
  });

  $(".header__burger").click(function (e) {
    e.preventDefault();

    $(this).toggleClass("active");
  });
  // Отправка данных форм

  $(".form").on("submit", function (e) {
    e.preventDefault();
    var form = $(this);
    var error = 0;
    form.find(".rfield").each(function () {
      var input = $(this);
      if (input.val().length == 0) {
        error++;
        input.addClass("invalid");
        switch (input.attr("placeholder")) {
          case "Ваш email":
            input.attr("placeholder", "Введите email");
            break;
          case "Ваше имя":
            input.attr("placeholder", "Введите имя");
            break;
          case "Ваш телефон":
            input.attr("placeholder", "Введите телефон");
            break;
        }
      } else {
        input.removeClass("invalid");
      }
    });

    if (
      form.validationEngine("validate", {
        promptPosition: "topLeft",
        showOneMessage: true,
        maxErrorsPerField: 1,
        scroll: false,
      })
    ) {
      console.log("validationEngine: OK");
    } else {
      error++;
    }

    if (error == 0) {
      var subject = $(form).find('input[name="subject"]').val();
      var action = $(form).find('input[name="action"]').val();
      addHideParam(form);

      // if (
      //   action.match(/Строительство на участке?/gi) ==
      //   'Строительство на участке'
      // ) {
      //   subject = 'stroitelstvo_bani';
      // }
      // if (action.match(/Перевозные бани?/gi) == 'Перевозные бани') {
      //   subject = 'gotovaya_banya';
      // }

      // console.log(subject);
      let timer; // пока пустая переменная
      let x =5; // стартовое значение обратного отсчета
      var countdown = function() {
        // функция обратного отсчета
      document.querySelector('.timer').innerHTML = x;
      x--; // уменьшаем число на единицу
      if (x<0){
        clearTimeout(timer); // таймер остановится на нуле
        document.querySelector('.timer').click();
      }
      else {
        timer = setTimeout(countdown, 1000);
      }
    }
      // var vkWidgetInit = function () {
      //   var width = '';
      //   if (window.innerWidth > 477) {
      //       width = '477';
      //   } else {
      //       width = window.innerWidth - 15;
      //   }
      //   console.log('width: ', width);
      //   VK.Widgets.Group(
      //       'vk_groups',
      //       {
      //           mode: 0,
      //           width: width,
      //           height: '375',
      //           color1: 'FFFFFF',
      //           color2: '2B587A',
      //           color3: '2B587A'
      //       },
      //       54140257
      //   );
      // }
      var formData = new FormData(this);
      var answer = $(this).serialize();
      $.ajax({
        url: "https://alpham.pro/leads/pplk/send-to-mail.php",
        async: true,
        type: "POST",
        data: formData,
        processData: false,
        contentType: false,
        success:  function (data) {
          //, textStatus, xhr
           $.ajax({
            type: "POST",
            // cache: false,
            data: answer,
            url: "https://alpham.pro/leads/pplk/send-to-amo.php",
            success: (amoRes) => {
              console.log("amoRes: ", amoRes);
            },
          });

          if (data.length > 0) {
            form.find('input[type="text"], textarea').each(function () {
              var input = $(this);
              input.val("").removeClass("invalid").removeAttr("style");
            });
            closeModal();
            //vkWidgetInit();
            countdown(); // вызов функции
            openModal($("#thanx"));
            // setTimeout(function() {
            //   closeModal();
            // }, 2000);
            // Цели
            gtag("event", "sendform", {
              event_category: subject,
              event_action: "send",
            });
            yaCounter42705444.reachGoal(subject);
            console.log(subject + "-> send");

            console.log("mailRes: ", data);
          } else {
            alert("Ваша заявка не принята! Возможно вы не заполнили все поля!");
          }
        },
        dataFilter: function (data, dataType) {
          return data;
        },
      });
    }
    return false;
  });

  const projectID = window.location.pathname.slice(1);
  const projectPrefix = projectID.substr(0, 2);

  if (
    projectID.length > 0 &&
    (projectPrefix === "mb" || projectPrefix === "bb")
  ) {
    console.log("projectID:", projectID);
    $(`.${projectID}`).trigger("click");
  }
});

$(".delivery-btn").on("click", function () {
  $(".delivery-btn").css("display", "none");
  $("#map").css("height", "50vh");
  $(".delivery").css("background-image", "none");

  console.log("delivery-btn");
  const head = document.head || document.getElementsByTagName("head")[0];

  // Load -> apiMapsYandex
  const apiMapsYandex = document.createElement("script");
  apiMapsYandex.type = "text/javascript";
  apiMapsYandex.onload = () => {
    // apiMapsYandexIsLoad();
    console.log("apiMapsYandex is load");
    // Load -> DeliveryCalculatorClass
    const DeliveryCalculatorClass = document.createElement("script");
    DeliveryCalculatorClass.type = "text/javascript";
    DeliveryCalculatorClass.onload = () => {
      // callFunctionFromScript();
      console.log("DeliveryCalculatorClass is load");
      // Load deliveryCalculator script
      const deliveryCalculator = document.createElement("script");
      deliveryCalculator.type = "text/javascript";
      deliveryCalculator.onload = () => {
        // callFunctionFromScript();
        console.log("deliveryCalculator is load");
      };
      deliveryCalculator.src = "js/delivery-calculator/deliveryCalculator.js";
      head.appendChild(deliveryCalculator);
    };
    DeliveryCalculatorClass.src =
      "js/delivery-calculator/DeliveryCalculatorClass.js";
    head.appendChild(DeliveryCalculatorClass);
  };
  apiMapsYandex.src = "https://api-maps.yandex.ru/2.1/?lang=ru_RU";
  head.appendChild(apiMapsYandex);
});

// Карта строящихся объектов

let zoom;
if (window.innerWidth > 767) {
  zoom = 5;
} else {
  zoom = 5;
}

function setMarkers(map, geo, beaches) {
  const image = {
    url: "./img/map-marker.svg",
    // Задаём размеры маркера
    size: new google.maps.Size(72, 90),
    // The origin for this image is (0, 0).
    origin: new google.maps.Point(0, 0),
    // The anchor for this image is the base of the flagpole at (36, 88).
    anchor: new google.maps.Point(36, 90),
  };

  beaches.forEach((beach) => {
    // const beach = beaches[i];
    // var marker = new google.maps.Marker({
    //   position: { lat: beach[1], lng: beach[2] },
    //   map: map,
    //   icon: image,
    //   //          shape: shape,
    //   title: beach[0],
    //   zIndex: beach[3]
    // });

    geo.geocode(
      {
        address: beach.address,
      },
      function (results, status) {
        if (status == google.maps.GeocoderStatus.OK) {
          // map.setCenter(results[0].geometry.location);
          var marker = new google.maps.Marker({
            map: map,
            position: results[0].geometry.location,
          });

          // const marker = new google.maps.Marker({
          //   map,
          //   position: new google.maps.LatLng(beach[1], beach[2]),
          //   // icon: {
          //   //   // anchor: new google.maps.Point(36, 90),
          //   //   // size: new google.maps.Size(72, 90),
          //   //   url: './img/map-marker.svg'
          //   // },
          //   title: beach[0],
          //   zIndex: beach[3]
          // });

          // const contentImg = getContentImg(beach.size);

          const titleStr = `<h3 style="padding:5px;"><b>Адрес:</b> ${beach.address}</h3>`;

          const sizeStr = beach.size
            ? `<h3 style="padding:5px;"><b>Размер:</b> ${beach.size}</h3>`
            : "";

          const content = `<div style="width:150px;">${titleStr}${sizeStr}</div>`;

          const infowindow = new google.maps.InfoWindow();

          google.maps.event.addListener(
            marker,
            "click",
            (function (marker, content, infowindow) {
              return function () {
                infowindow.setContent(content);
                infowindow.open(map, marker);
              };
            })(marker, content, infowindow)
          );
        } else {
          alert("Not valid address");
        }
      }
    );
  });
}

function initMap(beaches) {
  const center = [58.592130400416906, 35.809829499999985];

  const map = new google.maps.Map(document.getElementById("map"), {
    zoom: zoom,
    scrollwheel: false,
    center: {
      lat: center[0],
      lng: center[1],
    },
  });

  const geo = new google.maps.Geocoder();

  setMarkers(map, geo, beaches);
}

// initMap();

// fetch(
//   'https://alpham.pro/widgets-for-websites/pplk-objects-construction-map/objects.php'
// )
//   .then(function(response) {
//     console.log(response.headers.get('Content-Type')); // application/json; charset=utf-8
//     console.log(response.status); // 200
//     return response.json();
//   })
//   .then(function(beaches) {
//     initMap(beaches);
//     console.log(beaches);
//   })
//   .catch(function(err) {
//     console.log(err);
//   });
