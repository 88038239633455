import $ from 'jquery';
// Подстановка телефонных номеров
function getUrlVars() {
  var vars = [],
      hash,
      hashes,
      href;

  href = window.location.href;
  hashes = window.location.href.slice(href.indexOf('?') + 1).split('&');
  for (var i = 0; i < hashes.length; i++) {
      hash = hashes[i].split('=');
      vars.push(hash[0]);
      vars[hash[0]] = hash[1];
  }
  return vars;
}

let phoneFlag = getUrlVars()['utm_geo']? getUrlVars()['utm_geo'] : '';

console.log('flag',phoneFlag);

if (phoneFlag === '' || phoneFlag === 'regions') {
  $('.krasnodar').addClass('phone-hide');
  $('.kaliningrad').addClass('phone-hide');
  $('.place').addClass('place-show');
}
if (phoneFlag !== 'moscow') {
  $('.place--spb').addClass('place-show');
} else if (phoneFlag !== 'petersburg') {
  $('.place--moscow').addClass('place-show');
}

if (phoneFlag === 'moscow') {
  $('.regions').addClass('phone-hide');
  $('.spb').addClass('phone-hide');
  $('.krasnodar').addClass('phone-hide');
  $('.kaliningrad').addClass('phone-hide');
  $('.place--pestovo').addClass('phone-hide');
}

if (phoneFlag === 'petersburg') {
  $('.regions').addClass('phone-hide');
  $('.moscow').addClass('phone-hide');
  $('.krasnodar').addClass('phone-hide');
  $('.kaliningrad').addClass('phone-hide');
}

if (phoneFlag === 'krasnodar') {
  $('.regions').addClass('phone-hide');
  $('.spb').addClass('phone-hide');
  $('.moscow').addClass('phone-hide');
  $('.kaliningrad').addClass('phone-hide');
  $('.place--spb').addClass('phone-hide');
  $('.place--pestovo').addClass('phone-hide');
  $('.krasnodar-delivery').html('Быстрая доставка от 3-х дней');
  $('.krasnodar-modal').html('Разгрузка и установка за счёт компании');
}

if (phoneFlag === 'kaliningrad') {
  $('.regions').addClass('phone-hide');
  $('.spb').addClass('phone-hide');
  $('.krasnodar').addClass('phone-hide');
  $('.moscow').addClass('phone-hide');
  $('.place--moscow').addClass('place-show');
}

