const counter = () => {
  const counterNode = document.querySelector(".stock__counter");
  if (counterNode) {
    const currentDate = new Date();
    const currDay = currentDate.getDate();
    let bathsleft = 0;

    switch (currDay) {
      case 1:
        bathsleft = 18;
        break;
      case 2:
        bathsleft = 18;
        break;
      case 3:
        bathsleft = 18;
        break;
      case 4:
        bathsleft = 16;
        break;
      case 5:
        bathsleft = 16;
        break;
      case 6:
        bathsleft = 16;
        break;
      case 7:
        bathsleft = 14;
        break;
      case 8:
        bathsleft = 14;
        break;
      case 9:
        bathsleft = 14;
        break;
      case 10:
        bathsleft = 12;
        break;
      case 11:
        bathsleft = 12;
        break;
      case 12:
        bathsleft = 12;
        break;
      case 13:
        bathsleft = 10;
        break;
      case 14:
        bathsleft = 10;
        break;
      case 15:
        bathsleft = 10;
        break;
      case 16:
        bathsleft = 8;
        break;
      case 17:
        bathsleft = 8;
        break;
      case 18:
        bathsleft = 8;
        break;
      case 19:
        bathsleft = 6;
        break;
      case 20:
        bathsleft = 6;
        break;
      case 21:
        bathsleft = 6;
        break;
      case 22:
        bathsleft = 4;
        break;
      case 23:
        bathsleft = 4;
        break;
      case 24:
        bathsleft = 4;
        break;
      case 25:
        bathsleft = 3;
        break;
      case 26:
        bathsleft = 3;
        break;
      case 27:
        bathsleft = 3;
        break;
      case 28:
        bathsleft = 2;
        break;
      case 29:
        bathsleft = 2;
        break;
      case 30:
        bathsleft = 2;
        break;
      case 31:
        bathsleft = 18;
        break;
    }
    counterNode.innerHTML = bathsleft;
  }
};

export default counter;