import $  from 'jquery';

function getUrlVar(e) {
  return getUrlVars()[e];
}

function getUrlVars() {
  var vars = [],
      hash,
      hashes,
      href;

  href = window.location.href;
  hashes = window.location.href.slice(href.indexOf('?') + 1).split('&');
  for (var i = 0; i < hashes.length; i++) {
      hash = hashes[i].split('=');
      vars.push(hash[0]);
      vars[hash[0]] = hash[1];
  }
  return vars;
}


function addHideParam(e) {
  if (e.find(".am-hide-block").length) return console.log("amHideBlock:  exist"), null;
  var target = $(e);
  var ahpValue = "ahp-value";
  var utmArray = ["utm_medium", "utm_source", "utm_campaign", "utm_term", "utm_content", "block", "placement", "position", "adposition", "source", "network", "keyword"];
  $("<div>").attr({
    type: "hidden",
    class: "am-hide-block"
  }).appendTo(target);
  var ahpBlock = $(".am-hide-block"),
    gclid = "";
  ga(function (target) {
    gclid = target.get("clientId");
    console.log("cid: ", gclid)
  });
  $("<input>").attr({
    type: "hidden",
    name: "cid",
    value: gclid
  }).appendTo(ahpBlock);
  utmArray.forEach(function (target, t) {
    ahpValue = getUrlVar(target);
    $("<input>").attr({
      type: "hidden",
      name: target,
      value: ahpValue
    }).appendTo(ahpBlock)
  })
}
export {addHideParam, getUrlVars};