import Vue from 'vue';
//import Vue from '../vendor/vue';
import {
  Carousel3d,
  Slide
} from 'vue-carousel-3d';
import $ from 'jquery';
import '../vendor/fancybox';

//Vue.config.productionTip = false;
const vueBlock = document.querySelector('#example');
if (vueBlock) {
  new Vue({
    el: "#example",
    data: {
      isAutoplay: true,
      slidesMobileBaths: {
        0: {
          id: 0,
          img: "/quiz/img/mb/s/4.jpg",
          webp: "/quiz/img/mb/s/4.webp",
          imgBig: "/quiz/img/mb/b/4.jpg",
        },
        1: {
          id: 1,
          img: "/quiz/img/mb/s/5.jpg",
          webp: "/quiz/img/mb/s/5.webp",
          imgBig: "/quiz/img/mb/b/5.jpg",
        },
        2: {
          id: 2,
          img: "/quiz/img/mb/s/6.jpg",
          webp: "/quiz/img/mb/s/6.webp",
          imgBig: "/quiz/img/mb/b/6.jpg",
        },
        3: {
          id: 3,
          img: "/quiz/img/mb/s/7.jpg",
          webp: "/quiz/img/mb/s/7.webp",
          imgBig: "/quiz/img/mb/b/7.jpg",
        },
        4: {
          id: 4,
          img: "/quiz/img/mb/s/8.jpg",
          webp: "/quiz/img/mb/s/8.webp",
          imgBig: "/quiz/img/mb/b/8.jpg",
        },
        5: {
          id: 5,
          img: "/quiz/img/mb/s/9.jpg",
          webp: "/quiz/img/mb/s/9.webp",
          imgBig: "/quiz/img/mb/b/9.jpg",
        },
        6: {
          id: 6,
          img: "/quiz/img/mb/s/10.jpg",
          webp: "/quiz/img/mb/s/10.webp",
          imgBig: "/quiz/img/mb/b/10.jpg",
        },
        7: {
          id: 7,
          img: "/quiz/img/mb/s/11.jpg",
          webp: "/quiz/img/mb/s/11.webp",
          imgBig: "/quiz/img/mb/b/11.jpg",
        },
        8: {
          id: 8,
          img: "/quiz/img/mb/s/12.jpg",
          webp: "/quiz/img/mb/s/12.webp",
          imgBig: "/quiz/img/mb/b/12.jpg",
        },
        9: {
          id: 9,
          img: "/quiz/img/mb/s/13.jpg",
          webp: "/quiz/img/mb/s/13.webp",
          imgBig: "/quiz/img/mb/b/13.jpg",
        },
        10: {
          id: 10,
          img: "/quiz/img/mb/s/14.jpg",
          webp: "/quiz/img/mb/s/14.webp",
          imgBig: "/quiz/img/mb/b/14.jpg",
        },
        11: {
          id: 11,
          img: "/quiz/img/mb/s/15.jpg",
          webp: "/quiz/img/mb/s/15.webp",
          imgBig: "/quiz/img/mb/b/15.jpg",
        },
        12: {
          id: 12,
          img: "/quiz/img/mb/s/16.jpg",
          webp: "/quiz/img/mb/s/16.webp",
          imgBig: "/quiz/img/mb/b/16.jpg",
        },
        13: {
          id: 13,
          img: "/quiz/img/mb/s/17.jpg",
          webp: "/quiz/img/mb/s/17.webp",
          imgBig: "/quiz/img/mb/b/17.jpg",
        },
        14: {
          id: 14,
          img: "/quiz/img/mb/s/18.jpg",
          webp: "/quiz/img/mb/s/18.webp",
          imgBig: "/quiz/img/mb/b/18.jpg",
        },
        15: {
          id: 15,
          img: "/quiz/img/mb/s/19.jpg",
          webp: "/quiz/img/mb/s/19.webp",
          imgBig: "/quiz/img/mb/b/19.jpg",
        },
        16: {
          id: 16,
          img: "/quiz/img/mb/s/20.jpg",
          webp: "/quiz/img/mb/s/20.webp",
          imgBig: "/quiz/img/mb/b/20.jpg",
        },
        17: {
          id: 17,
          img: "/quiz/img/mb/s/1.jpg",
          webp: "/quiz/img/mb/s/1.webp",
          imgBig: "/quiz/img/mb/b/1.jpg",
        },
        18: {
          id: 18,
          img: "/quiz/img/mb/s/2.jpg",
          webp: "/quiz/img/mb/s/2.webp",
          imgBig: "/quiz/img/mb/b/2.jpg",
        },
        19: {
          id: 19,
          img: "/quiz/img/mb/s/3.jpg",
          webp: "/quiz/img/mb/s/3.webp",
          imgBig: "/quiz/img/mb/b/3.jpg",
        },
      },
      slidesBuildBaths: {
        0: {
          id: 0,
          img: "/quiz/img/bb/s/4.jpg",
          webp: "/quiz/img/bb/s/4.webp",
          imgBig: "/quiz/img/bb/b/4.jpg",
        },
        1: {
          id: 1,
          img: "/quiz/img/bb/s/5.jpg",
          webp: "/quiz/img/bb/s/5.webp",
          imgBig: "/quiz/img/bb/b/5.jpg",
        },
        2: {
          id: 2,
          img: "/quiz/img/bb/s/6.jpg",
          webp: "/quiz/img/bb/s/6.webp",
          imgBig: "/quiz/img/bb/b/6.jpg",
        },
        3: {
          id: 3,
          img: "/quiz/img/bb/s/7.jpg",
          webp: "/quiz/img/bb/s/7.webp",
          imgBig: "/quiz/img/bb/b/7.jpg",
        },
        4: {
          id: 4,
          img: "/quiz/img/bb/s/8.jpg",
          webp: "/quiz/img/bb/s/8.webp",
          imgBig: "/quiz/img/bb/b/8.jpg",
        },
        5: {
          id: 5,
          img: "/quiz/img/bb/s/9.jpg",
          webp: "/quiz/img/bb/s/9.webp",
          imgBig: "/quiz/img/bb/b/9.jpg",
        },
        6: {
          id: 6,
          img: "/quiz/img/bb/s/10.jpg",
          webp: "/quiz/img/bb/s/10.webp",
          imgBig: "/quiz/img/bb/b/10.jpg",
        },
        7: {
          id: 7,
          img: "/quiz/img/bb/s/11.jpg",
          webp: "/quiz/img/bb/s/11.webp",
          imgBig: "/quiz/img/bb/b/11.jpg",
        },
        8: {
          id: 8,
          img: "/quiz/img/bb/s/12.jpg",
          webp: "/quiz/img/bb/s/12.webp",
          imgBig: "/quiz/img/bb/b/12.jpg",
        },
        9: {
          id: 9,
          img: "/quiz/img/bb/s/13.jpg",
          webp: "/quiz/img/bb/s/13.webp",
          imgBig: "/quiz/img/bb/b/13.jpg",
        },
        10: {
          id: 10,
          img: "/quiz/img/bb/s/14.jpg",
          webp: "/quiz/img/bb/s/14.webp",
          imgBig: "/quiz/img/bb/b/14.jpg",
        },
        11: {
          id: 11,
          img: "/quiz/img/bb/s/15.jpg",
          webp: "/quiz/img/bb/s/15.webp",
          imgBig: "/quiz/img/bb/b/15.jpg",
        },
        12: {
          id: 12,
          img: "/quiz/img/bb/s/16.jpg",
          webp: "/quiz/img/bb/s/16.webp",
          imgBig: "/quiz/img/bb/b/16.jpg",
        },
        13: {
          id: 13,
          img: "/quiz/img/bb/s/17.jpg",
          webp: "/quiz/img/bb/s/17.webp",
          imgBig: "/quiz/img/bb/b/17.jpg",
        },
        14: {
          id: 14,
          img: "/quiz/img/bb/s/18.jpg",
          webp: "/quiz/img/bb/s/18.webp",
          imgBig: "/quiz/img/bb/b/18.jpg",
        },
        15: {
          id: 15,
          img: "/quiz/img/bb/s/19.jpg",
          webp: "/quiz/img/bb/s/19.webp",
          imgBig: "/quiz/img/bb/b/19.jpg",
        },
        16: {
          id: 16,
          img: "/quiz/img/bb/s/20.jpg",
          webp: "/quiz/img/bb/s/20.webp",
          imgBig: "/quiz/img/bb/b/20.jpg",
        },
        17: {
          id: 17,
          img: "/quiz/img/bb/s/21.jpg",
          webp: "/quiz/img/bb/s/21.webp",
          imgBig: "/quiz/img/bb/b/21.jpg",
        },
        18: {
          id: 18,
          img: "/quiz/img/bb/s/22.jpg",
          webp: "/quiz/img/bb/s/22.webp",
          imgBig: "/quiz/img/bb/b/22.jpg",
        },
        19: {
          id: 19,
          img: "/quiz/img/bb/s/23.jpg",
          webp: "/quiz/img/bb/s/23.webp",
          imgBig: "/quiz/img/bb/b/23.jpg",
        },
        20: {
          id: 20,
          img: "/quiz/img/bb/s/24.jpg",
          webp: "/quiz/img/bb/s/24.webp",
          imgBig: "/quiz/img/bb/b/24.jpg",
        },
        21: {
          id: 21,
          img: "/quiz/img/bb/s/25.jpg",
          webp: "/quiz/img/bb/s/25.webp",
          imgBig: "/quiz/img/bb/b/25.jpg",
        },
        22: {
          id: 22,
          img: "/quiz/img/bb/s/26.jpg",
          webp: "/quiz/img/bb/s/26.webp",
          imgBig: "/quiz/img/bb/b/26.jpg",
        },
        23: {
          id: 23,
          img: "/quiz/img/bb/s/27.jpg",
          webp: "/quiz/img/bb/s/27.webp",
          imgBig: "/quiz/img/bb/b/27.jpg",
        },
        24: {
          id: 24,
          img: "/quiz/img/bb/s/28.jpg",
          webp: "/quiz/img/bb/s/28.webp",
          imgBig: "/quiz/img/bb/b/28.jpg",
        },
        25: {
          id: 25,
          img: "/quiz/img/bb/s/29.jpg",
          webp: "/quiz/img/bb/s/29.webp",
          imgBig: "/quiz/img/bb/b/29.jpg",
        },
        26: {
          id: 26,
          img: "/quiz/img/bb/s/30.png",
          webp: "/quiz/img/bb/s/30.webp",
          imgBig: "/quiz/img/bb/b/30.png",
        },
        27: {
          id: 27,
          img: "/quiz/img/bb/s/31.png",
          webp: "/quiz/img/bb/s/31.webp",
          imgBig: "/quiz/img/bb/b/31.png",
        },
        28: {
          id: 28,
          img: "/quiz/img/bb/s/32.png",
          webp: "/quiz/img/bb/s/32.webp",
          imgBig: "/quiz/img/bb/b/32.png",
        },
        29: {
          id: 29,
          img: "/quiz/img/bb/s/33.png",
          webp: "/quiz/img/bb/s/33.webp",
          imgBig: "/quiz/img/bb/b/33.png",
        },
        30: {
          id: 30,
          img: "/quiz/img/bb/s/1.jpg",
          webp: "/quiz/img/bb/s/1.webp",
          imgBig: "/quiz/img/bb/b/1.jpg",
        },
        31: {
          id: 31,
          img: "/quiz/img/bb/s/2.jpg",
          webp: "/quiz/img/bb/s/2.webp",
          imgBig: "/quiz/img/bb/b/2.jpg",
        },
        32: {
          id: 32,
          img: "/quiz/img/bb/s/3.jpg",
          webp: "/quiz/img/bb/s/3.webp",
          imgBig: "/quiz/img/bb/b/3.jpg",
        },
      },
      slidesPotfolio: {
        0: {
          id: 0,
          img: "img/projects/4.jpg",
          imgBig: "img/projects/b/4.jpg",
        },
        1: {
          id: 1,
          img: "img/projects/5.jpg",
          imgBig: "img/projects/b/5.jpg",
        },
        2: {
          id: 2,
          img: "img/projects/6.jpg",
          imgBig: "img/projects/b/6.jpg",
        },
        3: {
          id: 3,
          img: "img/projects/7.jpg",
          imgBig: "img/projects/b/7.jpg",
        },
        4: {
          id: 4,
          img: "img/projects/8.jpg",
          imgBig: "img/projects/b/8.jpg",
        },
        5: {
          id: 5,
          img: "img/projects/9.jpg",
          imgBig: "img/projects/b/9.jpg",
        },
        6: {
          id: 6,
          img: "img/projects/10.jpg",
          imgBig: "img/projects/b/10.jpg",
        },
        7: {
          id: 7,
          img: "img/projects/11.jpg",
          imgBig: "img/projects/b/11.jpg",
        },
        8: {
          id: 8,
          img: "img/projects/12.jpg",
          imgBig: "img/projects/b/12.jpg",
        },
        9: {
          id: 9,
          img: "img/projects/13.jpg",
          imgBig: "img/projects/b/13.jpg",
        },
        10: {
          id: 10,
          img: "img/projects/14.jpg",
          imgBig: "img/projects/b/14.jpg",
        },
        11: {
          id: 11,
          img: "img/projects/15.jpg",
          imgBig: "img/projects/b/15.jpg",
        },
        12: {
          id: 12,
          img: "img/projects/16.jpg",
          imgBig: "img/projects/b/16.jpg",
        },
        13: {
          id: 13,
          img: "img/projects/17.jpg",
          imgBig: "img/projects/b/17.jpg",
        },
        14: {
          id: 14,
          img: "img/projects/18.jpg",
          imgBig: "img/projects/b/18.jpg",
        },
        15: {
          id: 15,
          img: "img/projects/19.jpg",
          imgBig: "img/projects/b/19.jpg",
        },
        16: {
          id: 16,
          img: "img/projects/20.jpg",
          imgBig: "img/projects/b/20.jpg",
        },
        17: {
          id: 17,
          img: "img/projects/21.jpg",
          imgBig: "img/projects/b/21.jpg",
        },
        18: {
          id: 18,
          img: "img/projects/22.jpg",
          imgBig: "img/projects/b/22.jpg",
        },
        19: {
          id: 19,
          img: "img/projects/23.jpg",
          imgBig: "img/projects/b/23.jpg",
        },
        20: {
          id: 20,
          img: "img/projects/24.jpg",
          imgBig: "img/projects/b/24.jpg",
        },
        21: {
          id: 21,
          img: "img/projects/25.jpg",
          imgBig: "img/projects/b/25.jpg",
        },
        22: {
          id: 22,
          img: "img/projects/26.jpg",
          imgBig: "img/projects/b/26.jpg",
        },
        23: {
          id: 23,
          img: "img/projects/27.jpg",
          imgBig: "img/projects/b/27.jpg",
        },
        24: {
          id: 24,
          img: "img/projects/28.jpg",
          imgBig: "img/projects/b/28.jpg",
        },
        25: {
          id: 25,
          img: "img/projects/29.jpg",
          imgBig: "img/projects/b/29.jpg",
        },
        26: {
          id: 26,
          img: "img/projects/30.jpg",
          imgBig: "img/projects/b/30.jpg",
        },
        27: {
          id: 27,
          img: "img/projects/31.jpg",
          imgBig: "img/projects/b/31.jpg",
        },
        28: {
          id: 28,
          img: "img/projects/32.jpg",
          imgBig: "img/projects/b/32.jpg",
        },
        29: {
          id: 29,
          img: "img/projects/33.jpg",
          imgBig: "img/projects/b/33.jpg",
        },
        30: {
          id: 30,
          img: "img/projects/34.jpg",
          imgBig: "img/projects/b/34.jpg",
        },
        31: {
          id: 31,
          img: "img/projects/35.jpg",
          imgBig: "img/projects/b/35.jpg",
        },
        32: {
          id: 32,
          img: "img/projects/36.jpg",
          imgBig: "img/projects/b/36.jpg",
        },
        33: {
          id: 33,
          img: "img/projects/37.jpg",
          imgBig: "img/projects/b/37.jpg",
        },
        34: {
          id: 34,
          img: "img/projects/38.jpg",
          imgBig: "img/projects/b/38.jpg",
        },
        35: {
          id: 35,
          img: "img/projects/39.jpg",
          imgBig: "img/projects/b/39.jpg",
        },
        36: {
          id: 36,
          img: "img/projects/40.jpg",
          imgBig: "img/projects/b/40.jpg",
        },
        37: {
          id: 37,
          img: "img/projects/41.jpg",
          imgBig: "img/projects/b/41.jpg",
        },
        38: {
          id: 38,
          img: "img/projects/42.jpg",
          imgBig: "img/projects/b/42.jpg",
        },
        39: {
          id: 39,
          img: "img/projects/43.jpg",
          imgBig: "img/projects/b/43.jpg",
        },
        40: {
          id: 40,
          img: "img/projects/44.jpg",
          imgBig: "img/projects/b/44.jpg",
        },
        41: {
          id: 41,
          img: "img/projects/45.jpg",
          imgBig: "img/projects/b/45.jpg",
        },
        42: {
          id: 42,
          img: "img/projects/46.jpg",
          imgBig: "img/projects/b/46.jpg",
        },
        43: {
          id: 43,
          img: "img/projects/47.jpg",
          imgBig: "img/projects/b/47.jpg",
        },
        44: {
          id: 44,
          img: "img/projects/48.jpg",
          imgBig: "img/projects/b/48.jpg",
        },
        45: {
          id: 45,
          img: "img/projects/49.jpg",
          imgBig: "img/projects/b/49.jpg",
        },
        46: {
          id: 46,
          img: "img/projects/50.jpg",
          imgBig: "img/projects/b/50.jpg",
        },
        47: {
          id: 47,
          img: "img/projects/51.jpg",
          imgBig: "img/projects/b/51.jpg",
        },
        48: {
          id: 48,
          img: "img/projects/52.jpg",
          imgBig: "img/projects/b/52.jpg",
        },
        49: {
          id: 49,
          img: "img/projects/53.jpg",
          imgBig: "img/projects/b/53.jpg",
        },
        50: {
          id: 50,
          img: "img/projects/54.jpg",
          imgBig: "img/projects/b/54.jpg",
        },
        51: {
          id: 51,
          img: "img/projects/55.jpg",
          imgBig: "img/projects/b/55.jpg",
        },
        52: {
          id: 52,
          img: "img/projects/56.jpg",
          imgBig: "img/projects/b/56.jpg",
        },
        53: {
          id: 53,
          img: "img/projects/57.jpg",
          imgBig: "img/projects/b/57.jpg",
        },
        54: {
          id: 54,
          img: "img/projects/58.jpg",
          imgBig: "img/projects/b/58.jpg",
        },
        55: {
          id: 55,
          img: "img/projects/59.jpg",
          imgBig: "img/projects/b/59.jpg",
        },
        56: {
          id: 56,
          img: "img/projects/60.jpg",
          imgBig: "img/projects/b/60.jpg",
        },
        57: {
          id: 57,
          img: "img/projects/61.jpg",
          imgBig: "img/projects/b/61.jpg",
        },
        58: {
          id: 58,
          img: "img/projects/62.jpg",
          imgBig: "img/projects/b/62.jpg",
        },
        59: {
          id: 59,
          img: "img/projects/63.jpg",
          imgBig: "img/projects/b/63.jpg",
        },
        60: {
          id: 60,
          img: "img/projects/64.jpg",
          imgBig: "img/projects/b/64.jpg",
        },
        61: {
          id: 61,
          img: "img/projects/65.jpg",
          imgBig: "img/projects/b/65.jpg",
        },
        62: {
          id: 62,
          img: "img/projects/66.jpg",
          imgBig: "img/projects/b/66.jpg",
        },
        63: {
          id: 63,
          img: "img/projects/67.jpg",
          imgBig: "img/projects/b/67.jpg",
        },
        64: {
          id: 64,
          img: "img/projects/68.jpg",
          imgBig: "img/projects/b/68.jpg",
        },
        65: {
          id: 65,
          img: "img/projects/69.jpg",
          imgBig: "img/projects/b/69.jpg",
        },
        66: {
          id: 66,
          img: "img/projects/70.jpg",
          imgBig: "img/projects/b/70.jpg",
        },
        67: {
          id: 67,
          img: "img/projects/1.jpg",
          imgBig: "img/projects/b/1.jpg",
        },
        68: {
          id: 68,
          img: "img/projects/2.jpg",
          imgBig: "img/projects/b/2.jpg",
        },
        69: {
          id: 69,
          img: "img/projects/3.jpg",
          imgBig: "img/projects/b/3.jpg",
        },
        70: {
          id: 70,
          img: "img/projects/71.png",
          imgBig: "img/projects/b/71.png",
        },
        71: {
          id: 71,
          img: "img/projects/72.png",
          imgBig: "img/projects/b/72.png",
        },
        72: {
          id: 72,
          img: "img/projects/73.png",
          imgBig: "img/projects/b/73.png",
        },
        73: {
          id: 73,
          img: "img/projects/74.png",
          imgBig: "img/projects/b/74.png",
        },
        74: {
          id: 74,
          img: "img/projects/75.png",
          imgBig: "img/projects/b/75.png",
        },
        75: {
          id: 75,
          img: "img/projects/76.png",
          imgBig: "img/projects/b/76.png",
        },
        76: {
          id: 76,
          img: "img/projects/77.png",
          imgBig: "img/projects/b/77.png",
        },
        77: {
          id: 77,
          img: "img/projects/78.png",
          imgBig: "img/projects/b/78.png",
        }
      },
      slidesVideo: {
        0: {
          id: 0,
          video: "https://www.youtube.com/embed/ZCCfx5-tQDE",
          img: "img/video_15.jpg",
        },
        1: {
          id: 1,
          video: "https://www.youtube.com/embed/SsWlebo9XnM",
          img: "img/video_16.jpg",
        },
        2: {
          id: 2,
          video: "https://www.youtube.com/embed/eMOYgQ6NrUo",
          img: "img/video_17.jpg",
        },
        3: {
          id: 3,
          video: "https://www.youtube.com/embed/N7Uf4qY0Nr4",
          img: "img/video_18.jpg",
        },
        4: {
          id: 4,
          video: "https://www.youtube.com/embed/Zd7Enpcbx5E",
          img: "img/video_19.jpg",
        },
        5: {
          id: 5,
          video: "https://www.youtube.com/embed/IhZA3wGCQO4",
          img: "img/video_1.jpg",
        },
        6: {
          id: 6,
          video: "https://www.youtube.com/embed/ynyxa_OCunA",
          img: "img/video_2.jpg",
        },
        7: {
          id: 7,
          video: "https://www.youtube.com/embed/NgrZk5yz-Ec",
          img: "img/video_3.jpg",
        },
        8: {
          id: 8,
          video: "https://www.youtube.com/embed/mgxdmjdOwZw",
          img: "img/video_4.jpg",
        },
        9: {
          id: 9,
          video: "https://www.youtube.com/embed/RhyHDPCUyf8",
          img: "img/video_5.jpg",
        },
        10: {
          id: 10,
          video: "https://www.youtube.com/embed/ZWJB4FZm-dU",
          img: "img/video_6.jpg",
        },
        11: {
          id: 11,
          video: "https://www.youtube.com/embed/bMU9H6iRJiw",
          img: "img/video_7.jpg",
        },
        12: {
          id: 12,
          video: "https://www.youtube.com/embed/VkFeAdjotLo",
          img: "img/video_8.jpg",
        },
        13: {
          id: 13,
          video: "https://www.youtube.com/embed/5MQ5P7Q3dm0",
          img: "img/video_9.jpg",
        },
        14: {
          id: 14,
          video: "https://www.youtube.com/embed/OIAgxERcCDU",
          img: "img/video_10.jpg",
        },
        15: {
          id: 15,
          video: "https://www.youtube.com/embed/1Fl2TpQonKQ",
          img: "img/video_11.jpg",
        },
        16: {
          id: 16,
          video: "https://www.youtube.com/embed/dCrJpfFTFEE",
          img: "img/video_12.jpg",
        },
        17: {
          id: 17,
          video: "https://www.youtube.com/embed/gob-ER1ckH4",
          img: "img/video_13.jpg",
        },
        18: {
          id: 18,
          video: "https://www.youtube.com/embed/WKyaJ8o-M4s",
          img: "img/video_14.jpg",
        },
      },
      slidesFabrick: {
        0: {
          id: 0,
          img: "img/sec13/b/1.jpg",
          imgBig: "img/sec13/b/1.jpg",
        },
        1: {
          id: 1,
          img: "img/sec13/b/2.jpg",
          imgBig: "img/sec13/b/2.jpg",
        },
        2: {
          id: 2,
          img: "img/sec13/b/3.jpg",
          imgBig: "img/sec13/b/3.jpg",
        },
        3: {
          id: 3,
          img: "img/sec13/b/4.jpg",
          imgBig: "img/sec13/b/4.jpg",
        },
        4: {
          id: 4,
          img: "img/sec13/b/5.jpg",
          imgBig: "img/sec13/b/5.jpg",
        },
        5: {
          id: 5,
          img: "img/sec13/b/6.jpg",
          imgBig: "img/sec13/b/6.jpg",
        },
        6: {
          id: 6,
          img: "img/sec13/b/7.jpg",
          imgBig: "img/sec13/b/7.jpg",
        },
        7: {
          id: 7,
          img: "img/sec13/b/8.jpg",
          imgBig: "img/sec13/b/8.jpg",
        },
        8: {
          id: 8,
          img: "img/sec13/b/9.jpg",
          imgBig: "img/sec13/b/9.jpg",
        },
        9: {
          id: 9,
          img: "img/sec13/b/10.jpg",
          imgBig: "img/sec13/b/10.jpg",
        },
        10: {
          id: 10,
          img: "img/sec13/b/11.jpg",
          imgBig: "img/sec13/b/11.jpg",
        },
        11: {
          id: 11,
          img: "img/sec13/b/12.jpg",
          imgBig: "img/sec13/b/12.jpg",
        },
        12: {
          id: 12,
          img: "img/sec13/b/13.jpg",
          imgBig: "img/sec13/b/13.jpg",
        },
        13: {
          id: 13,
          img: "img/sec13/b/14.jpg",
          imgBig: "img/sec13/b/14.jpg",
        },
        14: {
          id: 14,
          img: "img/sec13/b/15.jpg",
          imgBig: "img/sec13/b/15.jpg",
        },
      },
    },
    components: {
      Carousel3d,
      Slide
    },
    methods: {
      onSlideClick() {
        alert("Остановись слайдер!!!");
        this.isAutoplay = false;
      },
    },
  });

  $().fancybox({
    selector: ".slider__link--video",
    loop: true,
    scrolling: "no",
    btnTpl: {
      arrowLeft: '<button id="prev" data-fancybox-prev class="fancybox-button fancybox-button--arrow_left" title="Назад"></button>',
      arrowRight: '<button id ="next" data-fancybox-next class="fancybox-button fancybox-button--arrow_right" title="Вперед"></button>',
    },
    helpers: {
      overlay: {
        locked: true,
      },
    },
    onStart: function () {
      $("#fancybox-overlay").css({
        position: "fixed"
      });
    },
  });

  $().fancybox({
    selector: ".slider__link--img:visible",
    loop: true,
    btnTpl: {
      arrowLeft: '<button id="prev" data-fancybox-prev class="fancybox-button fancybox-button--arrow_left" title="Назад"></button>',
      arrowRight: '<button id ="next" data-fancybox-next class="fancybox-button fancybox-button--arrow_right" title="Вперед"></button>',
    }
  });

  $().fancybox({
    selector: ".slider__link--img",
    loop: true,
    btnTpl: {
      arrowLeft: '<button id="prev" data-fancybox-prev class="fancybox-button fancybox-button--arrow_left" title="Назад"></button>',
      arrowRight: '<button id ="next" data-fancybox-next class="fancybox-button fancybox-button--arrow_right" title="Вперед"></button>',
    }
    /* ,
       afterShow: function() {
         console.log("Остановись слайдер!!!");
       } */
    ,
  });

}